export default {
  computed: {
    hostURL() {
      return window.chatterConfig.hostURL;
    },
    vapidPublicKey() {
      return window.chatterConfig.vapidPublicKey;
    },
    enabledLanguages() {
      return window.chatterConfig.enabledLanguages;
    },
    isEnterprise() {
      return window.chatterConfig.isEnterprise === 'true';
    },
  },
};
